
// Libraries Import
import React, {useState,Suspense,useEffect} from 'react';
import {Switch,Route} from 'react-router-dom';
import {useSelector} from 'react-redux';

// Routes Import
import AuthRoutes from '../routes/AuthRoute';
import AdminRoutes from '../routes/AdminRoutes';
import UserRoutes from '../routes/UserRoutes';

// import UserRoutes from '../routes/LocalAdminRoutes';

// // Components Import
import Sidebar from '../components/Sidebar/Sidebar';
import MiniSidebar from '../components/Sidebar/MiniSidebar';
import MobileSidebar from '../components/Sidebar/MobileSidebar';

import TopBar from '../components/TopBar/Topbar';
import Footer from '../components/Footer';
import {FullScreenLoading} from "../components/Loading";

// users links
import { adminLinks } from './adminLinks';
import { userLinks } from './userLinks';
// import { AnimatePresence, motion } from 'framer-motion';

//roles
import {ADMIN_ROLE, TRAINEE_ROLE} from "../constants";

function BaseRoute() {

  const [miniSidebar, setMiniSidebar] = useState(false);
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const user = useSelector(state => state.user);

  useEffect(() => {
    if (mobileSidebar) {
      document.body.style.overflow = 'hidden'; // Disable scrolling
    } else {
      document.body.style.overflow = ''; // Restore scrolling
    }
    
    return () => {
      document.body.style.overflow = ''; // Cleanup on component unmount
    };
  }, [mobileSidebar]);

  let links = adminLinks;
  
    if(user.role === ADMIN_ROLE){
      links = adminLinks;
    }
    else{
      links = userLinks;
    }


  return (  
    <div className="flex flex-col h-full">
      
      {
  mobileSidebar ? (
    <>
      {/* Overlay to block interaction with the background */}
      <div
        onClick={() => setMobileSidebar(false)}
        className="bg-black bg-opacity-25 fixed top-0 left-0 right-0 bottom-0 z-10"
      />
      
    </>
  ) : null
}


      

      <Suspense fallback={<FullScreenLoading/>}>
      <Switch>
        {/* if user is not logged-in we will show him auth 
        routes which contains login form */}
          {!user? (
            <>
            <div className=" w-full h-full flex flex-col justify-between">
              {/* <div className="flex-grow relative"> */}
                <AuthRoutes /> 
              {/* </div> */}
            </div> 
            </>
          ):null
          }

          {/* if user is logged-in we will show him other routes based on user role with topbar and sidebar*/}
          
          {user ? (
            <>
            <TopBar mobileSidebar={mobileSidebar} setMobileSidebar={setMobileSidebar} miniSidebar={miniSidebar} setMiniSidebar={setMiniSidebar} />
            {/* mobile sidebar for small devices */}
            {
              mobileSidebar?
              <>
                <MobileSidebar setMobileSidebar={setMobileSidebar} links={links} />
                </>
              :
              null
              
            }
            <div className={`w-full h-full flex flex-col justify-between pl-0  
            ${miniSidebar?'lg:pl-16':' lg:pl-250 2xl:pl-300'}`}>
              
              {/* setting full sidebar and mini sidebar, 
              mini sidebar contains just icons,
              it appears when we click on hamburger icon of topbar
              */}
              <div>
              {   !miniSidebar? 
                  <Sidebar mobileSidebar={mobileSidebar} miniSidebar={miniSidebar} links={links}/>
                :
                  <MiniSidebar miniSidebar={miniSidebar} links={links} />
              }
              </div>

              <div className="flex-grow border-1 shadow-md m-3 relative">
                {
                  (user.role === ADMIN_ROLE) ? 
                    <AdminRoutes />
                  :(user.role === TRAINEE_ROLE) ? 
                    <UserRoutes />
                  :
                  null
                }
              </div>
              <Footer/>
            </div>
            </>
          ): null}

        </Switch>
        </Suspense>
        
      </div>
  )
}

export default BaseRoute
