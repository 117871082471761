import React from 'react';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

const MobileSidebar = ({links,setMobileSidebar}) => {

    

    return (
        <>
         
        <div className="transition transform fixed top-20 md:top-0 h-full py-10  w-full md:w-1/2 bg-gray-light overflow-y-auto z-10000">
            <div onClick={()=>{setMobileSidebar(false)}} className="w-full text-3xl font-light text-red-primary px-5 flex justify-end">
                <FaTimes />
            </div>
            <div>
                {links.map((link,index) => (
                    <Link key={index} to={link.to} className="flex cursor-pointer items-center hover:bg-gray-dark  border-b-2 border-gray-dark px-8 py-5"
                    onClick={()=>{setMobileSidebar(false)}}
                    >
                        <span className="pr-3">{link.icon}</span>
                        <h1 className="text-lg">{link.title}</h1>
                    </Link>
                ))}
            </div>
        </div> 
        </>
    )
}

export default MobileSidebar
