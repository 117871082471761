import { store } from "react-notifications-component";
import * as yup from "yup";
import DOMPurify from 'dompurify';

import draftToHtml from 'draftjs-to-html';
import { convertToRaw, ContentState, convertFromHTML, EditorState } from 'draft-js';


export const getAuthUser = () => {
    let token = localStorage.getItem("access_token");
    if(token){
        return JSON.parse(token).user;
    } else{
        return false;
    }
}

export const getToken = () => {
    let token = localStorage.getItem("access_token");
    if(token){
        return JSON.parse(token).access_token;
    } else{
        return false;
    }
}

export const setToken = (access_token) => {
    localStorage.setItem("access_token", JSON.stringify(access_token));
}

export const removeToken =()=>{
    return localStorage.removeItem("access_token")
}

export const success_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "success",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const error_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "danger",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export const warning_alert = (message, title) => {
    store.addNotification({
        title: title ? title : "",
        message: message,
        type: "warning",
        insert: "bottom",
        container: "bottom-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: 7000,
            onScreen: true,
            showIcon: true
        }
    })
}

export function stringNotEmpty() {
    return yup.mixed().test({
        name: 'stringNotEmpty',
        exclusive: false,
        message: "Required",
        test: function(value) {
            if(value !== undefined){
                return value.trim() !== "";
            } else if(value === undefined){
                return false
            }
        },  
    });
}

export function add_suffix_number(i) {
    var j = i % 10,
        k = i % 100;
    if (j == 1 && k != 11) {
        return i + "st";
    }
    if (j == 2 && k != 12) {
        return i + "nd";
    }
    if (j == 3 && k != 13) {
        return i + "rd";
    }
    return i + "th";
}

export const getRawContentFromHtml = (content) => {
    return EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(content)
        ))
}

export const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data)
})

export const customTableStyles = {
    table: {
		style: {
            borderTopLeftRadius: '6px',
            borderTopRightRadius: '6px',
            overflow: 'hidden',
            boxShadow: "0 10px 20px 0 rgba(0,0,0,0.1)"
		},
	},
    tableWrapper: {
		style: {


		},
	},
    cells: {
		style: {
		},
		draggingStyle: {
            cursor: "pointer"
        },
	},
    rows: {
        style: {
            minHeight: '60px', // override the row height
            borderBottom: "0px solid #e2e8f0 !important",
            "&:last-child": {
                borderBottom: "none", // Removes bottom border for cells in the last row
            }
        }
    },
    headRow: {
        style: {
            width: "100%",
            borderBottom: "0",
            borderRadius: "20px",

        }
    },
    headCells: {
        style: {
            backgroundColor: "#043c7b",
            color: "#fff",
            fontSize: "13px",
            textTransform: "uppercase",
            fontWeight: "600",
            borderRight: "2px solid #062760",
            '&:hover': {
                backgroundColor: "#043c7b", 
                color: "#fff !important", 
                cursor: 'default',
            }
        },
    },
    
    cells: {
        style: {
            width: "100%",
            backgroundColor: "#fff",
            color: "#374151",
            fontWeight: "600",
            borderRight: "2px solid #dee2e7",
            borderBottom: "2px solid #dee2e7",
            padding: "5px 10px",
            "&> div:first-child": {
                width: "100%"
            },
            "&:last-child": {
                // borderBottom: "none", // Removes bottom border for cells in the last row
            }
            
        },
    },
    pagination: {
        style: {
            // backgroundColor: "#000",
            borderBottomRightRadius: "6px",
            borderBottomLeftRadius: "6px",
            // color: "#fff",
        },
        pageButtonsStyle: {
            color: "#4d4d4d !important",
            fill: "#4d4d4d !important",
            '&:disabled': {
				cursor: 'unset',
				color: "#d1d5db !important",
				fill: "#d1d5db !important",
			},
			'&:hover:not(:disabled)': {
				backgroundColor: "#fff !important",
			},
			'&:focus': {
				outline: 'none',
				backgroundColor: "#4d4d4d !important",
			},
        }
    }
};


export const update_name_storage=(first_name,last_name)=>{
    let Storageobject = localStorage.getItem("access_token");
    let parsedLocalStorageObject=JSON.parse(Storageobject);
    parsedLocalStorageObject.user.first_name=first_name;
    parsedLocalStorageObject.user.last_name=last_name;
    localStorage.setItem("access_token", JSON.stringify(parsedLocalStorageObject))

    // localStorage.setItem("access_token",  JSON.stringify({...localStorage.getItem("access_token"), ...parsedLocalStorageObject}));
    // localStorage.setItem("access_token", JSON.stringify({...Storageobject, ...parsedLocalStorageObject.user.first_name=first_name,...parsedLocalStorageObject.user.last_name=last_name }));



    
    // console.log(`object : ${JSON.parse(Storageobject).user.first_name}`)
    // console.log(`object : ${}`)
}


export const selectStylesFilter = error => (
    {
        
        control: (provided, state) => ({
            ...provided,
            height: "12px",
            fontSize: "12px",
            borderRadius: "0.375rem",
            
        }),
        
        option: (provided, state) => {
            return ({
                ...provided,
                fontSize: "12px",
            })
        },
        
        singleValue: (provided, state) => {
            return { ...provided,  fontSize: "12px" };
        }
    }
)


export const selectStylesField = (error, hasValue) => ({
    placeholder: (provided, state) => ({
        ...provided,
        fontSize: "0.875rem",
        color: "#9ca3af"
    }),
    control: (provided, state) => ({
        ...provided,
        // backgroundColor: state.isDisabled ? "#fafafa" : "#f6fbff",
        borderRadius: "7px",
        
        minHeight: "46px",

        border: state.isDisabled
            ? "2px solid #d1d5db"
            : state.isFocused
                ? "2px solid #043c7b"
                : state.isSelected
                    ? "2px solid #043c7b"
                    : state.hasValue || hasValue
                        ? "2px solid #F3F4F6"
                        : error
                            ? " "
                            : "2px solid #E5E7EB",
        boxShadow: state.isFocused ? "0px 0px 0px #043c7b" : "none",
        "&:hover": {
            //   border: "2px solid #E5E7EB",
            boxShadow: "0px 0px 0px #674fd4",
        },
        fontSize: "0.875rem",
    }),

    option: (provided, state) => ({
        ...provided,
        background: state.isSelected ? "#043c7b !important" : "#FFFFFF",
        color: state.isSelected ? "#ffffff" : "#000 !important",
        "&:hover": {
            background: "#043c7b",
            color: "#ffffff !important",
        },
        fontSize: "0.875rem",
    }),
}
)